import cn from "classnames";
import { Link } from "gatsby";

import { useCountry } from "../../func-core/core/application/hooks/useCountry";
import { getLocalePathFromCountry } from "../../func-core/core/utils/navigation-utils";
import * as styles from "./Navigation.module.scss";

interface Image {
  default: string;
}

const logoSVG: Image = require("../../images/logo/barkibu.svg") as Image;

const logoSVGSrc: string = logoSVG.default;

const Navigation = (): JSX.Element => {
  const { country } = useCountry();

  return (
    <header
      id="header"
      className={cn(styles.navigation, styles.navigationOnboarding, "or-navigation")}
    >
      <Link to={getLocalePathFromCountry(country)} className={styles.brand}>
        <img src={logoSVGSrc} alt="Barkibu" width="90px" height="21px" />
      </Link>
    </header>
  );
};

export default Navigation;
