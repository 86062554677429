import { ReactNode } from "react";

import PageStepAnimation from "../../atoms/./page-step-animation/PageStepAnimation";
import Icons from "../../core/ui/images/icomoon/symbol-defs.svg";
import { useCountry } from "../../func-core/core/application/hooks/useCountry";
import PageWrapper from "../../organisms/page-wrapper/PageWrapper";
import useAxeptioScriptLoader from "../../utils/hooks/useAxeptioScriptLoader";
import useTrackOnboardingPageViewed from "../../utils/hooks/useTrackOnboardingPageViewed";
import Footer from "../footer/AlternativeFooter";
import Meta from "../meta/meta";
import Navigation from "../navigation/Onboarding";
import * as styles from "./OnboardingLayout.module.scss";

interface OnboardingLayoutProps {
  children: ReactNode;
}

const OnboardingLayout = ({ children }: OnboardingLayoutProps): JSX.Element => {
  const { country } = useCountry();

  useTrackOnboardingPageViewed();
  useAxeptioScriptLoader(country.language.code.split("-")[0].toLowerCase());

  return (
    <PageWrapper>
      <Meta
        title={undefined}
        lang={country.language.code}
        bodyClass={styles.onboardingLayout}
        disableTranslations={false}
        description={undefined}
        alternateVersions={false}
        slug={undefined}
      />
      <Navigation />
      <PageStepAnimation tag="main" adoptionClassName={styles.main}>
        {children}
      </PageStepAnimation>
      <Footer />
      <Icons />
    </PageWrapper>
  );
};

export default OnboardingLayout;
