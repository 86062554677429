export const toSnakeCase = (obj: Record<string, unknown>): Record<string, unknown> => {
  const snakeCaseObj: Record<string, unknown> = {};
  const keys: string[] = Object.keys(obj);

  for (const key of keys) {
    const snakeCaseKey: string = key.replace(/[A-Z]/g, (match) => `_${match.toLowerCase()}`);
    snakeCaseObj[snakeCaseKey] = obj[key];
  }

  return snakeCaseObj;
};

export const toCamelCase = (obj: Record<string, unknown>): Record<string, unknown> => {
  const camelCaseObj: Record<string, unknown> = {};
  const keys: string[] = Object.keys(obj);

  for (const key of keys) {
    const camelCaseKey: string = key.replace(/_([a-z])/g, (_, match) => match.toUpperCase());
    camelCaseObj[camelCaseKey] = obj[key];
  }

  return camelCaseObj;
};
